// constants.js
export const productData = [
    {
        id: 1,
        // title: 'Your product title 1',
        text: 'Coming soon',
        tags: [],
    },
    // {
    //     id: 1,
    //     title: 'Your product title 1',
    //     text: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage',
    //     tags: ['Front-End', 'Front-End', 'Front-End'],
    // },
    // {
    //     id: 2,
    //     title: 'Your product title 2',
    //     text: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage',
    //     tags: ['Front-End', 'Front-End', 'Front-End'],
    // },
    // {
    //     id: 3,
    //     title: 'Your product title 3',
    //     text: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage',
    //     tags: ['Front-End', 'Front-End', 'Front-End'],
    // },
];

import React from 'react';

const Header = () => {

    return (
        <div className="header">
            <div className="header-content">
                
                <div className="header-title">
                    Explore the World of Innovative Apps
                </div>
                <div className="header-text">
                At Supernova Labs we are dedicated to leveraging technology for practical solutions that seamlessly integrate into your daily life. As a small app startup, our focus is on thoughtful innovation and user-centric design. Our journey begins with a simple belief: technology should be intuitive and enhance, not complicate, your experiences
                </div>
            </div>
        </div>
    );
};

export default Header;
